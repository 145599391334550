import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FsInvitationService, FsOrganisationService, ProfileService } from '@fairandsmart/angular';
import {
  EntityIdentifierHelper,
  InvitationStatus,
  OrganisationView,
  ProfileStatus,
  ProfileType,
} from '@fairandsmart/types';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '@Env';
import { AuthService } from '@Services/auth.service';
import { USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH } from '@Common/constants';

@Component({
  selector: 'fs-membership-invitation',
  templateUrl: './membership-invitation.component.html',
  styleUrls: ['./membership-invitation.component.scss', '../welcome-page/welcome-page.component.scss'],
})
export class MembershipInvitationComponent implements OnInit, OnDestroy {
  readonly CUSTOMIZATION = environment.customization;

  error: { code: string, id?: string, message?: string, localizedMessage?: string };

  joinError: { code: string, id: string, message: string, localizedMessage: string };

  organisation: OrganisationView;

  step: 'acceptInvite' | 'completeProfile' | 'joinError' | 'joinSuccessful' | null;

  completeProfileForm: UntypedFormGroup;

  private invitationId: string;

  public organisationName: string;

  private secret: string;

  private email: string;

  private userid: string;

  readonly USERNAME_MIN_LENGTH = USERNAME_MIN_LENGTH;

  readonly USERNAME_MAX_LENGTH = USERNAME_MAX_LENGTH;

  private routeSubscription: Subscription;

  constructor(
    private fsOrganisationService: FsOrganisationService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private profileService: ProfileService,
    private fsInvitationService: FsInvitationService,
  ) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.invitationId = params.get('id');
      this.secret = params.get('secret');
      this.userid = params.get('userid');
      try {
        this.organisationName = params.has('name') ? atob(params.get('name')) : null;
        this.email = params.has('email') ? atob(params.get('email')) : null;
      } catch (e) {
        console.error(e.message);
        this.email = null;
        this.organisationName = null;
      }
      if (!this.invitationId || !this.secret || !this.email || !this.organisationName) {
        console.error('[invite] missing params', params);
        this.error = { code: 'MISSING_URL_PARAMS' };
        return;
      }

      if (this.auth.isAuthenticated()) {
        this.join().catch((e) => console.error(e));
      } else {
        this.step = 'acceptInvite';
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription != null) {
      this.routeSubscription.unsubscribe();
    }
  }

  private async join(): Promise<void> {
    this.step = null;
    await this.auth.ready;
    if (this.profileService.getProfile().email.toLowerCase() !== this.email.toLowerCase()) {
      console.error(`[invite] email mismatch: expected ${this.email} but was ${this.profileService.getProfile().email}`);
      this.error = { code: 'EMAIL_MISMATCH' };
      return;
    }
    if (this.profileService.getProfile().organisations[this.invitationId] != null) {
      await this.goToOrganisation();
      return;
    }
    if (this.profileService.getStatus() === ProfileStatus.ACTIVE && this.profileService.getType() !== ProfileType.PRO) {
      console.error(`[invite] wrong profile type: expected PRO but was ${this.profileService.getType()}`);
      this.error = { code: 'PROFILE_TYPE' };
      return;
    }
    if (this.profileService.getType() !== ProfileType.PRO) {
      await this.profileService.updateType(ProfileType.PRO).toPromise().catch((error) => this.displayJoinError(error));
      if (this.profileService.getType() !== ProfileType.PRO) {
        return;
      }
    }
    if (this.profileService.getProfile().username == null || this.profileService.getProfile().username.length === 0) {
      this.step = 'completeProfile';
      this.completeProfileForm = new UntypedFormGroup({
        username: new UntypedFormControl('', [
          Validators.required, Validators.minLength(USERNAME_MIN_LENGTH), Validators.maxLength(USERNAME_MAX_LENGTH),
        ]),
      });
      return;
    }
    if (this.profileService.getStatus() === ProfileStatus.DRAFT) {
      await this.profileService.updateStatus(ProfileStatus.ACTIVE).toPromise().catch((error) => this.displayJoinError(error));
      if (this.profileService.getStatus() !== ProfileStatus.ACTIVE) {
        return;
      }
    }
    this.performJoin();
  }

  private performJoin() {
    this.fsInvitationService.update(this.invitationId, { secret: this.secret, status: InvitationStatus.ACCEPTED }).pipe(
      mergeMap((invitation) => this.fsOrganisationService.get(EntityIdentifierHelper.extractId(invitation.reference))),
      mergeMap((organisation: OrganisationView) => {
        this.organisation = organisation;
        return this.profileService.refreshProfile();
      }),
    ).subscribe(() => {
      this.step = 'joinSuccessful';
    }, (error) => this.displayJoinError(error));
  }

  displayJoinError(error) {
    console.error(error);
    this.joinError = error.error;
    this.step = 'joinError';
  }

  completeProfile(): void {
    if (this.completeProfileForm.valid) {
      this.step = null;
      this.profileService.updateUsername(this.completeProfileForm.get('username').value).subscribe(() => {
        this.join();
      }, (error) => this.displayJoinError(error));
    }
  }

  goToOrganisation(): Promise<boolean> {
    return this.router.navigate(['/']);
  }

  acceptInvite(): Promise<void> {
    if (this.userid || !environment.customization.allowAccountCreation) {
      return this.auth.keycloak.login({ loginHint: this.email, idpHint: environment.authIdpHint });
    }
    return this.auth.keycloak.register({ loginHint: this.email, idpHint: environment.authIdpHint });
  }

  logout(): void {
    // noinspection JSIgnoredPromiseFromCall
    this.auth.keycloak.logout();
  }
}
