import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  FsWebSocketMessage,
  FsWebsocketsService,
  isEventMessage,
  isSubscriptionMessage,
  ProfileService,
} from '@fairandsmart/angular';
import { Store } from '@ngrx/store';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import {
  EMPTY, from, Observable, of,
} from 'rxjs';
import {
  catchError, concatMap, finalize, tap,
} from 'rxjs/operators';
import { WS_RECONNECTION_TIMEOUT } from '@Common/constants';
import { CoreEvent } from '@Common/common.actions';
import { environment } from '@Env';
import { FsOrgState } from '../reducers';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private initializedPromise: Promise<void>;

  constructor(
    private router: Router,
    public keycloak: KeycloakService,
    private profile: ProfileService,
    private fsWebsocketsService: FsWebsocketsService,
    private store: Store<FsOrgState>,
    public alertService: AlertService,
  ) {
    if (this.isAuthenticated()) {
      this.createReadyPromise();
      this.statusMonitoring().subscribe();
    }
  }

  /**
   * Ready when profile is loaded
   */
  public get ready(): Promise<void> {
    return this.initializedPromise || this.initProfile();
  }

  public isAuthenticated(): boolean {
    return this.keycloak.getKeycloakInstance().authenticated;
  }

  public getUserId(): string {
    return this.keycloak.getKeycloakInstance().idTokenParsed.sub;
  }

  public hasRealmRole(role: string): boolean {
    return this.keycloak.getKeycloakInstance().hasRealmRole(role);
  }

  private initProfile(): Promise<void> {
    try {
      this.createReadyPromise();
      return this.initializedPromise;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  private createReadyPromise(): void {
    try {
      const userid: string = this.keycloak.getKeycloakInstance().idTokenParsed.sub;
      this.initializedPromise = this.profile.init(userid).pipe(
        tap(() => this.connectWebSocket()),
      ).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

  private connectWebSocket(): void {
    this.fsWebsocketsService.listen(this.profile.id).pipe(
      tap((message: FsWebSocketMessage) => {
        if (isEventMessage(message)) {
          this.store.dispatch(new CoreEvent(message));
        } else if (!isSubscriptionMessage(message)) {
          console.warn('[WS] Unhandled WebSocket message received', message);
        }
      }),
      catchError((err) => {
        console.error(err);
        return of(null);
      }),
      finalize(() => {
        window.setTimeout(() => this.connectWebSocket(), WS_RECONNECTION_TIMEOUT);
      }),
    ).subscribe();
  }

  private statusMonitoring(): Observable<any> {
    return this.keycloak.keycloakEvents$.asObservable().pipe(
      concatMap((event) => {
        switch (event.type) {
          case KeycloakEventType.OnAuthLogout:
            this.router.navigate(['/']);
            return this.alertService.promptConfirmDialog({
              data: {
                title: 'AUTH.LOGOUT.TITLE',
                content: 'AUTH.LOGOUT.CONTENT',
                confirm: 'AUTH.LOGOUT.BUTTONS.RECONNECT',
                cancel: 'COMMON.BUTTONS.CANCEL',
              },
              autoFocus: false,
              closeOnNavigation: false,
            }).afterClosed().pipe(
              tap((login: boolean) => {
                if (login) {
                  this.router.navigate(['/'], { queryParams: { from: this.router.url } }).then(() => {
                    this.keycloak.login({ idpHint: environment.authIdpHint });
                  });
                }
              }),
            );
          case KeycloakEventType.OnTokenExpired:
            return from(this.keycloak.updateToken());
          default:
            return EMPTY;
        }
      }),
      catchError((err) => {
        console.error(err);
        return EMPTY;
      }),
    );
  }
}
