<img class="logo" alt="logo" [style]="CUSTOMIZATION.splashLogoStyle" [src]="CUSTOMIZATION.splashLogoUrl">
<div *ngIf="error" class="info mat-text-warn">{{error}}</div>
<div class="login-card-wrapper">
  <div class="login-card" [class.login-card-2x]="CUSTOMIZATION.allowAccountCreation">
    <div class="card-block account">
      <h1>{{'WELCOME.LOGIN_TITLE' | translate}}</h1>
      <p class="fade-text">{{'WELCOME.ALREADY_REGISTERED' | translate}}</p>
      <button mat-flat-button color="primary" (click)=login() id="BTN-signin">
        {{'WELCOME.BUTTONS.SIGN_IN' | translate}}
      </button>
      <hr class="separator">
      <p class="fade-text">{{'WELCOME.INVITED' | translate}}</p>
      <p class="fade-text mat-caption">{{'WELCOME.INVITED_HELP' | translate}}</p>
    </div>
    <div *ngIf="CUSTOMIZATION.allowAccountCreation" class="card-block no-account">
      <h2>{{'WELCOME.NO_ACCOUNT' | translate}}</h2>
      <p class="fade-text">{{'WELCOME.NO_ACCOUNT_HELP' | translate}}</p>
      <div>
        <button (click)=register() mat-flat-button color="default" id="BTN-signup">
          {{'WELCOME.BUTTONS.SIGN_UP' | translate}}
        </button>
      </div>
      <div>
        <a mat-button href="https://www.fairandsmart.com">{{'WELCOME.MORE_INFORMATION' | translate}}</a>
      </div>
    </div>
  </div>
  <div class="copyright">
    <p *ngIf="CUSTOMIZATION.footerCopyrights">{{CUSTOMIZATION.footerCopyrights}} {{CURRENT_YEAR}}</p>
  </div>
</div>
