import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@Env';
import { AuthService } from '@Services/auth.service';
import { FsOrgCustomization } from '../../../../environments/fs-org-environment';

@Component({
  selector: 'fs-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  error: string;

  readonly CUSTOMIZATION: FsOrgCustomization = environment.customization;

  readonly CURRENT_YEAR = new Date().getFullYear();

  constructor(private auth: AuthService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.error) {
      this.error = this.route.snapshot.queryParams.error;
    }
  }

  login(): void {
    this.auth.keycloak.login({ idpHint: environment.authIdpHint });
  }

  register(): void {
    this.auth.keycloak.register();
  }
}
